<script setup>
/* Vue3 */
import {ref, reactive, onMounted} from 'vue';
import {useRouter} from "vue-router";
/* Velocity.js */
import Velocity from 'velocity-animate';
import {gsap} from "gsap";
import axios from "axios";


const router = useRouter();
const body = ref(null);
const _ref = reactive({
    body,
});

const logout = async () => {
    const response = await axios.post('/api/admin/logout');
    if (response.data.result === 'success') {
        await router.push({name: 'loginAdminPrimary'});
    }
}

onMounted(() => {
    /**
     * ToggleMenu Open Event
     */
    window.addEventListener('doToggleMenuOpen', e => {
        Promise.resolve()
            .then(() => {
                return new Promise(resolve => {
                    console.log('ハンバーガ:OPEN');
                    Velocity(_ref.body, 'stop');
                    return resolve();
                })
            })
            .then(() => {
                Velocity(_ref.body, 'fadeIn', {
                    duration: e.duration,
                });
            })
    }, false);

    /**
     * ToggleMenu Close Event
     */
    window.addEventListener('doToggleMenuClose', e => {
        Promise.resolve()
            .then(() => {
                console.log('ハンバーガー:CLOSE');
                return new Promise(resolve => {
                    Velocity(_ref.body, 'stop');
                    return resolve();
                })
            })
            .then(() => {
                Velocity(_ref.body, 'fadeOut', {
                    duration: e.duration,
                });
            })
    }, false);
});

/**
 * ハンバーガーボタンクリックイベントを発火させるためのカスタムイベントを登録
 */
const onLinkClick = () => {
    // カスタムイベント発行 イベント先-> Hamburger.vue
    window.dispatchEvent(new CustomEvent('clickHamburgerBtn'));
};

</script>

<template>
    <div ref="body" class="ly_toggleMenu">
        <div class="ly_toggleMenu_inner">
            <div class="toggleMenu">
                <p>
                    <!--                    <img src="/wp-content/themes/ysk/src/assets/img/childline-symbol-mark.svg"-->
                    <!--                         alt="チャイルドラインのシンボルマーク"-->
                    <!--                         class="toggleMenu_img">-->
                </p>
                <nav class="toggleMenu_unit mt-80">
                    <ul class="toggleMenu_list">
                        <li class="toggleMenu_item">
                            <router-link :to="{name: 'home'}"
                                         class="toggleMenu_link el_btnA d-inline-block w_auto"
                                         @click.native="onLinkClick"
                            >
                                HOME
                            </router-link>
                        </li>
                        <li class="toggleMenu_item">
                            <router-link :to="{name: 'clientSearch'}"
                                         class="toggleMenu_link el_btnA d-inline-block w_auto"
                                         @click.native="onLinkClick"
                            >
                                検索
                            </router-link>
                        </li>
                        <li class="toggleMenu_item">
                            <router-link :to="{name: 'clientRegister', params: {type: 'register'}}"
                                         class="toggleMenu_link el_btnA d-inline-block w_auto"
                                         @click.native="onLinkClick"
                            >
                                新規登録
                            </router-link>
                        </li>
                        <li class="toggleMenu_item">
                            <a href="" @click.prevent="logout()"
                               class="toggleMenu_link el_btnA d-inline-block w_auto"
                            >
                                ログアウト
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>


<style scoped lang="scss">
@import 'src/assets/scss/modules/variables';

.ly_toggleMenu {
    z-index: 900;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $secondary;
    display: none;
    opacity: 0;
    overflow: scroll;
}

.ly_toggleMenu_inner {
    padding: 120px 15px;
    //height: 100%;
}

.toggleMenu {
    //position: relative;
    //top: 50%;
    //transform: translateY(-80%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.toggleMenu_img {
    height: 130px;
    @media ($min-md) {
        height: 180px;
    }
}

.toggleMenu_unit {
    width: 100%;
}

.toggleMenu_list {
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;
}

.toggleMenu_item {
    display: flex;
    justify-content: center;

    &:last-child {
        margin-top: 50px;
    }
}

.toggleMenu_link {
    display: flex;
    justify-content: center;
    width: 100%;
    line-height: 50px;
    color: $base;
    font-size: 1.5rem;
    letter-spacing: 2px;
    text-decoration: none;
    @media ($min-md) {
        line-height: 50px;
        font-size: 2.0rem;
    }

    &:hover {
        filter: drop-shadow(0 0 10px $base);
    }

    &:last-child {
        margin-bottom: 0;
    }
}
</style>
