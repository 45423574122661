import {createRouter, createWebHistory} from 'vue-router'
import axios from "axios";

import Header from '../views/HeaderView.vue'
import HomeView from '../views/HomeView.vue'
import SearchView from "../views/SearchView.vue";

const LoginAdminPrimary = () => import('../views/auth/LoginAdmin.vue')
const LoginAdminSecondary = () => import('../views/auth/LoginAdminSecondary.vue');
const RegisterAdminPrimary = () => import('../views/auth/RegisterAdminPrimary.vue');
const RegisterAdminSecondary = () => import('../views/auth/RegisterAdminSecondary.vue');
const RegisterAdminTertiary = () => import('../views/auth/RegisterAdminTertiary.vue');
const RegisterAdminQuaternary = () => import('../views/auth/RegisterAdminQuaternary.vue');

const ClientData = () => import('../views/ClientData.vue');
const ClientRegistration = () => import('../views/ClientRegistration.vue');
const ClientCarsRegistration = () => import('../views/ClientCarsRegistration.vue');

const router = createRouter({
    // history: createWebHistory(import.meta.env.BASE_URL),
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            components: {
                default: HomeView,
                header: Header,
            },
            meta: {
                isAuth: true
            }
        },
        {
            path: '/search',
            name: 'clientSearch',
            components: {
                default: SearchView,
                header: Header,
            },
            meta: {
                isAuth: true
            }
        },
        {
            path: '/client/:cid',
            name: 'clientData',
            components: {
                default: ClientData,
                header: Header,
            },
            meta: {
                isAuth: true
            }
        },
        {
            path: '/admin/client/:type',
            name: 'clientRegister',
            components: {
                default: ClientRegistration,
                header: Header,
            },
            meta: {
                isAuth: true
            }
        },
        {
            path: '/admin/client-cars/:type', // updateの場合は クエリ ?cid=1&car_id=3
            name: 'clientCars',
            components: {
                default: ClientCarsRegistration,
                header: Header,
            },
            meta: {
                isAuth: true
            }
        },
        /**
         * 登録（ユーザー）
         */
        // {
        //     path: '/register',
        //     name: 'registerUser',
        //     component: () => import('../views/auth/RegisterUserPrimary.vue'),
        // },
        // {
        //     path: '/register/:email',
        //     name: 'registerUserSecondary',
        //     component: () => import('../views/auth/RegisterUserSecondary.vue'),
        // },
        // {
        //     path: '/register/tertiary',
        //     name: 'registerUserTertiary',
        //     component: () => import('../views/auth/RegisterUserTertiary.vue')
        // },
        // {
        //     path: '/register/quaternary',
        //     name: 'registerUserQuaternary',
        //     component: () => import('../views/auth/RegisterUserQuaternary.vue')
        // },

        /**
         * 登録（管理者）
         */
        {
            path: '/admin/register',
            name: 'registerAdmin',
            components: {
                auth: RegisterAdminPrimary,
            },
            component: () => import('../views/auth/RegisterAdminPrimary.vue'),
        },
        {
            path: '/admin/register/:email',
            name: 'registerAdminSecondary',
            components: {
                auth: RegisterAdminSecondary,
            },
            component: () => import('../views/auth/RegisterAdminSecondary.vue'),
        },
        {
            path: '/admin/register/tertiary',
            name: 'registerAdminTertiary',
            components: {
                auth: RegisterAdminTertiary,
            },
            component: () => import('../views/auth/RegisterAdminTertiary.vue')
        },
        {
            path: '/admin/register/quaternary',
            name: 'registerAdminQuaternary',
            components: {
                auth: RegisterAdminQuaternary,
            },
        },
        // \END 登録（管理者）

        /**
         * ログイン user
         */
        // {
        //     path: '/login',
        //     name: 'loginUserPrimary',
        //     component: () => import('../views/auth/LoginUserPrimary.vue')
        // },
        // {
        //     path: '/login/:email',
        //     name: 'loginUserSecondary',
        //     component: () => import('../views/auth/LoginUsersSecondary.vue')
        // },

        /**
         * ログイン (管理者)
         */
        {
            path: '/admin/login',
            name: 'loginAdminPrimary',
            components: {
                auth: LoginAdminPrimary
            },
            meta: {
                isUncertified: true,
            },
        },
        {
            path: '/admin/login/:email',
            name: 'loginAdminSecondary',
            components: {
                auth: LoginAdminSecondary
            },
            meta: {
                isUncertified: true,
            }
        },

        // {
        //     path: '/logout',
        //     name: 'logout',
        //     component: () => import('../views/auth/Logout'),
        //     meta: {
        //         isAuth: true
        //     }
        // },

        // {
        //     path: '/about',
        //     name: 'aboutUser',
        //     component: () => import('../views/AboutUser'),
        //     meta: {
        //         isAuth: true
        //     }
        // },
        // {
        //     path: '/admin/about',
        //     name: 'aboutAdmin',
        //     component: () => import('../views/admin/AboutAdmin'),
        //     meta: {
        //         isAuth: true,
        //     },
        // },
        // {
        //     path: '/verify/email/user',
        //     name: 'verifyUserEmail',
        //     component: () => import('../views/auth/VerifyEmail')
        // },
    ],
    scrollBehavior() {
        console.log('scrollBehavior');
    },
})

router.beforeEach(async (to, from, next) => {
    //TODO
    // セッションストレージに結果がない時　かつ、 認証が必要なページの時の処理を実装すること。

    // const authCheck = sessionStorage.getItem('auth');
    const isAuth = to.matched.some(route => route.meta.isAuth);
    const isUncertified = to.matched.some(route => route.meta.isUncertified);

    // console.log('認証確認（セッションストレージの値）', authCheck);
    console.log('認証が必要なページですか？', isAuth);
    console.log('未認証でなければいけないページですか？', isUncertified);

    // 認証が必要なページの時
    // if (isAuth && authCheck) {
    //     return next();
    // }

    // 認証が必要なページの時
    // if (isAuth && !authCheck) {
    if (isAuth) {
        try {
            // adminセッションガードで認証されているか問い合わせる。
            const result = await axios.post('/api/admin/auth');
            // 認証チェックの結果を出力。認証されていたら true になる。
            console.log('グローバル beforeEach auth check', result.data);
            // 認証されていた場合。
            if (result.data) {
                // sessionStorage.setItem('auth', 'true');
                return next();
                // 未認証の場合。
            } else {
                return next({name: 'loginAdminPrimary'});
            }
        } catch (error) {
            console.log(error.request.status);
            if (error.request.status === 419) {
                await router.push({name: 'loginAdminPrimary'});
            }
        }
    }

    // 未認証でないといけないページの時
    // if (isUncertified && !authCheck) {
    if (isUncertified) {
        const result = await axios.post('/api/admin/auth');
        if (result.data) {
            return next({name: 'loginAdminPrimary'});
        } else {
            return next();
        }
    }

    // 認証済み、未認証、どちらの状態でもアクセス可能なページの時。
    next()
})

export default router
