<script setup>
import {RouterLink, RouterView} from 'vue-router'
import LoaderView from "./views/LoaderView.vue";
</script>

<template>
    <router-view name="header"/>
    <!--    <transition name="fade" mode="out-in" appear>-->
    <!--        <RouterView/>-->
    <!--    </transition>-->
    <div class="ly_body">
        <router-view name="auth"/>
        <router-view v-slot="{Component}">
            <transition
                name="fade"
                mode="out-in"
                appear
            >
<!--                <keep-alive>-->
                    <component :is="Component"/>
<!--                </keep-alive>-->
            </transition>
        </router-view>
    </div>
    <LoaderView/>
    <footer></footer>
</template>

<style lang="scss">
@import "src/assets/scss/modules/variables";
/*
 * 表示するときのアニメーション
 *
 * .fade-enter-fromは, 表示状態になった直後に消える
 * .fade-enter-activeは, 表示状態になる直前に付与され, アニメーションが終わると消える
 * .fade-enter-toは, 表示状態になった直後に付与(同時にfromが消える)され, アニメーションが終わると消える
 */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 500ms ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

html {
    height: 100%;
    font-size: 62.5%;
}

body {
    position: relative;
    height: 100%;
    min-height: 100%;
    font-size: 1.3rem;
    font-family: NotoSansJP, sans-serif; /* mac , windows */
    font-weight: 400;
    font-style: normal;
    color: $accent4;
    //background-color: #a9a9a9;
    background-color: #E0E0E0;
}

.ly_body {
    height: 100%;
    min-height: 100%;
    //display: grid;
    grid-template-rows: 1fr auto;
}

footer {
    grid-row: 2/3;
}

.container__primary {
    //@media ($min-sm) {
    //    padding-right: 4rem;
    //    padding-left: 4rem;
    //}
    @media ($max-md) {
        padding-right: 4rem;
        padding-left: 4rem;
    }
}


main {
    padding: 80px 0px 0px 0px;

    @media ($min-md) {
        margin-left: 200px;
        padding: 100px 30px 30px 30px;
    }
}

$base: #91804E;
$main: #F78F22;
$accent1: #C49308;
$accent2: #61F8F8;
$accent3: #08C490;
$accent4: #1c1c1c;

</style>
