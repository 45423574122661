<template>
    <div class="btnHamburgerWrap d-md-none">
        <div ref="btn" id="js_btnHamburger">
<!--        <button ref="btn" id="js_btnHamburger"-->
<!--                class="btnHamburger"-->
<!--                type="button"-->
<!--                aria-controls="global-nav"-->
<!--                aria-expanded="false"-->
<!--                aria-label="メニューを開閉する">-->
            <span ref="lineTop" class="btnHamburger_line"></span>
            <span ref="lineMid" class="btnHamburger_line"></span>
            <span ref="lineBtm" class="btnHamburger_line"></span>
        </div>
    </div>
</template>

<script>
import Velocity from 'velocity-animate';

export default {
    name: "Hamburger",
    data() {
        return {
            // ブレイクポイント
            breakPoint: 768,
            // ハンバーガーボタンの幅
            btnWidth: 32,
            btnWidhtMd: 42,
            // ハンバーガーボタンの高さ
            btnHeight: 20,
            btnHeightMd: 28,
            // ハンバーガーアイコンの線の幅
            lineWidthOffset: 0,
            lineWidthOffsetMd: 0,
            // ハンバーガーアイコンの線の高さ
            lineHeight: 2,
            lineHeightMd: 2,
            // 閉じている時のハンバーガーアイコンの線の色
            lineCloseColor: '#000',
            // 開いている時の時のハンバーガーアイコンの線の色
            lineOpenColor: '#FFF',

            duration: 300,
            durationM: 500,
            lines: [],
            // toggleMenu     : document.getElementById('js_toggleMenu'),
            closeFlag: 1,
            fireFlag: 0,
            closeEveHandler: '',
            openEveHandler: '',
        }
    },
    mounted() {
        // ボタンの3本の線を配列にまとめる
        this.lines = [this.$refs.lineTop, this.$refs.lineMid, this.$refs.lineBtm];
        // 3本線に基本スタイルを適応
        this.lines.forEach(line => {
            line.style.backgroundColor = this.lineCloseColor;
        });

        this.adjustStyleForBtnWidth();
        this.adjustStyleForBtnHeight();
        this.adjustStyleForLineTop();
        this.adjustStyleForLineWidth();
        this.adjustStyleForLineHeight();
        this.closeEveHandler = this.closeEvent.bind(this);
        this.openEveHandler  = this.openEvent.bind(this);
        // リサイズのイベント
        window.addEventListener('resize', this.closeEveHandler, false);
        // ハンバーガーアイコン　クリックイベント監視
        this.$refs.btn.addEventListener('click', this.click.bind(this));

        // 外部コンポーネントからカスタムイベントを監視（ハンバーガーアイコンをクリック命令を監視）
        // clickメソッド呼び出し、flgで判断してアニメーションを実行させる。
        window.addEventListener('clickHamburgerBtn', this.click.bind(this));
    },
    methods: {
        /**
         * ハンバーガーボタンの幅のスタイルを調整する（height）
         */
        adjustStyleForBtnWidth() {
            if (window.innerWidth < this.breakPoint) {
                this.$refs.btn.style.width = this.btnWidth + 'px';
            } else {
                this.$refs.btn.style.width = this.btnWidhtMd + 'px';
            }
        },
        /**
         * ハンバーガーボタンの（高さ）のスタイルを調整する（height）
         */
        adjustStyleForBtnHeight() {
            if (window.innerWidth < this.breakPoint) {
                this.$refs.btn.style.height = this.btnHeight + 'px';
            } else {
                this.$refs.btn.style.height = this.btnHeightMd + 'px';
            }
        },
        /**
         * ハンバーガーボタンの線の横幅のスタイルを調整する（width）
         */
        adjustStyleForLineWidth() {
            if (window.innerWidth < this.breakPoint) {
                this.lines.forEach(line => {
                    line.style.width = this.btnWidth - this.lineWidthOffset + 'px';
                });
            } else {
                this.lines.forEach(line => {
                    line.style.width = this.btnWidhtMd - this.lineWidthOffsetMd + 'px';
                });
            }
        },
        /**
         * ハンバーガーボタンの線の高さのスタイルを調整する（height）
         */
        adjustStyleForLineHeight() {
            if (window.innerWidth < this.breakPoint) {
                this.lines.forEach(line => {
                    line.style.height = this.lineHeight + 'px';
                });
            } else {
                this.lines.forEach(line => {
                    line.style.height = this.lineHeightMd + 'px';
                });
            }
        },
        /**
         * ハンバーガーボタンの線と線の間隔のスタイルを調整する（top）
         */
        adjustStyleForLineTop() {
            if (window.innerWidth < this.breakPoint) {
                if (this.closeFlag) {
                    this.$refs.lineTop.style.top = 0 + 'px';
                    this.$refs.lineMid.style.top = (this.btnHeight - this.lineHeight) / 2 + 'px';
                    this.$refs.lineBtm.style.top = (this.btnHeight - this.lineHeight) + 'px';
                } else {
                    this.lines.forEach(line => {
                        line.style.top = (this.btnHeight - this.lineHeight) / 2 + 'px';
                    })
                }
            } else {
                if (this.closeFlag) {
                    this.$refs.lineTop.style.top = 0 + 'px';
                    this.$refs.lineMid.style.top = (this.btnHeightMd - this.lineHeightMd) / 2 + 'px';
                    this.$refs.lineBtm.style.top = (this.btnHeightMd - this.lineHeightMd) + 'px';
                } else {
                    this.lines.forEach(line => {
                        line.style.top = (this.btnHeightMd - this.lineHeightMd) / 2 + 'px';
                    })
                }
            }
        },

        setFlagOpen() {
            this.closeFlag = 0;
        },

        setFlagClose() {
            this.closeFlag = 1;
        },

        /**
         * このメソッドを使用して、クリックイベントを発生させる事が出来る
         */
        click() {
            if (this.closeFlag) {
                this.open();
            } else {
                this.close();
            }
        },

        /**
         * ボタンが閉じている時に、リサイズした時のイベント内容
         */
        closeEvent() {
            let timer = false;
            if (!timer) clearTimeout(timer);
            timer = setTimeout(() => {
                this.adjustStyleForBtnWidth();
                this.adjustStyleForBtnHeight();
                this.adjustStyleForLineTop();
                this.adjustStyleForLineWidth();
                this.adjustStyleForLineHeight();
            }, 100);
        },

        /**
         * ボタンが開いている時に、リサイズした時のイベント内容
         */
        openEvent() {
            let timer = false;
            if (!timer) clearTimeout(timer);
            timer = setTimeout(() => {
                this.adjustStyleForBtnWidth();
                this.adjustStyleForBtnHeight();
                this.adjustStyleForLineWidth();
                this.adjustStyleForLineHeight();
                this.adjustStyleForLineTop();
                // this.$refs.lineTop.style.top = window.getComputedStyle(this.lineMid).top;
                // this.$refs.lineBtm.style.top = window.getComputedStyle(this.lineMid).top;
            }, 100);
        },

        /**
         * ボタンを開ける時の、アニメーションの内容
         */
        open() {
            if (this.fireFlag) {
                Promise.resolve()
                    .then(() => {
                        return new Promise(resolve => {
                            /* カスタムイベント発行 */
                            const customEvent = new CustomEvent('doToggleMenuStop');
                            window.dispatchEvent(customEvent);

                            Velocity(this.$refs.lineTop, 'stop');
                            Velocity(this.$refs.lineMid, 'stop');
                            Velocity(this.$refs.lineBtm, 'stop');
                            this.changeFlagOpen();
                            return resolve();
                        })
                    })
                    .then(() => {
                        this.click();
                    })
            } else {
                this.fireFlag = 1;

                // トグルメニューのアニメーションに利用
                /* カスタムイベント発行 */
                const customEvent = new CustomEvent('doToggleMenuOpen', {'duration': this.duration});
                window.dispatchEvent(customEvent);

                // this.changeFlagOpen();
                window.removeEventListener('resize', this.closeEveHandler, false);
                window.addEventListener('resize', this.openEveHandler, false);

                // ライン(上)へのアニメーション
                Velocity(this.$refs.lineTop, {
                    rotateZ: '30deg',
                    top: window.getComputedStyle(this.$refs.lineMid).top,
                    backgroundColor: this.lineOpenColor,
                }, {
                    duration: this.duration
                });

                // ライン(中)へのアニメーション
                Velocity(this.$refs.lineMid, {opacity: 0}, {duration: this.duration});

                // ライン(下)へのアニメーション
                Velocity(this.$refs.lineBtm, {
                    rotateZ: '-30deg',
                    top: window.getComputedStyle(this.$refs.lineMid).top,
                    backgroundColor: this.lineOpenColor,
                }, {
                    duration: this.duration,
                    complete: this.changeFlagOpen.bind(this), // flagを切り替え
                });
            }
        },

        /**
         * ボタンを閉じる時の、アニメーションの内容
         */
        close() {
            if (this.fireFlag) {
                Promise.resolve()
                    .then(() => {
                        return new Promise(resolve => {
                            /* カスタムイベント発行 */
                            const customEvent = new CustomEvent('doToggleMenuStop');
                            window.dispatchEvent(customEvent);

                            Velocity(this.$refs.lineTop, 'stop');
                            Velocity(this.$refs.lineMid, 'stop');
                            Velocity(this.$refs.lineBtm, 'stop');
                            // Velocity(this.toggleMenu, 'stop');
                            this.changeFlagClose();
                            return resolve();
                        })
                    })
                    .then(() => {
                        this.click();
                    });
            } else {
                this.fireFlag = 1;

                // トグルメニューのアニメーションに利用
                /* カスタムイベント発行 */
                const customEvent = new CustomEvent('doToggleMenuClose', {'duration': this.duration});
                window.dispatchEvent(customEvent);

                // this.changeFlagClose();
                window.removeEventListener('resize', this.openEveHandler, false);
                window.addEventListener('resize', this.closeEveHandler, false);

                // ライン(上)へのアニメーション
                Velocity(this.$refs.lineTop, {
                    rotateZ: 0,
                    top: 0,
                    backgroundColor: this.lineCloseColor,
                }, {
                    duration: this.duration
                });

                // ライン(中)へのアニメーション
                Velocity(this.$refs.lineMid, {opacity: 1}, {duration: this.duration});

                // ライン(下)へのアニメーション
                Velocity(this.$refs.lineBtm, {
                    rotateZ: 0,
                    top: parseInt(window.getComputedStyle(this.$refs.lineMid).top) * 2 + 'px',
                    backgroundColor: this.lineCloseColor,
                }, {
                    duration: this.duration,
                    complete: this.changeFlagClose.bind(this), // flagを切り替え
                });
            }
        },

        changeFlagOpen() {
            this.fireFlag = 0;
            this.setFlagOpen();
        },

        changeFlagClose() {
            this.fireFlag = 0;
            this.setFlagClose();
        },
    },
}
</script>

<!--<style scoped lang="scss" src="../../../assets/scss/modules/hamburger.scss">-->
<style scoped lang="scss">
@import "src/assets/scss/modules/variables";
///*  ハンバーガーボタン
// 991.98表示　992非表示
//============================== */
/**
hamburger.jsファイル用
 */
.btnHamburgerWrap {
    z-index: 1000;
    position: fixed;
    top: 13px;
    left: 15px;
    @media ($min-md) {
        top: 18px;
        right: 30px;
    }
    @media ($min-lg) {
        position: static;
        z-index: auto;
        right: auto;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.btnHamburger {
    position: relative;
    text-decoration: none;
    appearance: none;
    border: none;
    cursor: pointer;
    outline: none!important;
}

.btnHamburger_line {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    -webkit-transition: inherit;
    transition: inherit;
    filter: drop-shadow(0 0 8px #FFF);
}

.btnHamburger_txt {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}
</style>
