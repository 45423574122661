import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import { createPinia } from 'pinia'
import axios from 'axios';
import VueAxios from 'vue-axios';

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLeaf, faSearch, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faLeaf)
library.add(faSearch)
library.add(faEye)
// \END FontAwesome



// SCSS読み込み
require('./assets/main.scss');


const app = createApp(App)

app.use(createPinia())

app.use(router)

app.use(VueAxios, axios)

// FontAwesomeで使いたいアイコンをここに入れる
app.component('fa', FontAwesomeIcon )

app.mount('#app')

/* axiosグローバル設定 */
// ヘッダーに追加
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// クッキーをクライアントサイドに送信を許可
axios.defaults.withCredentials = true;
