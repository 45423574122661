import { defineStore } from 'pinia'

export const useLoaderStore = defineStore('loader', () => {


    const start = () => {
        const loader = document.getElementById('loader');
        loader.classList.add('isLoading');
    }

    const finish = () => {
        const loader = document.getElementById('loader');
        loader.classList.remove('isLoading');
    }

    return { start, finish }
})
