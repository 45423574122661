import { defineStore } from 'pinia'

export const useJapanCalendarStore = defineStore('japanCalendar', () => {
    /**
     * 和暦から西暦へ変換
     *
     * @param era
     * @param year
     * @returns {number}
     */
    function japanCalendarToAD(era, year) {
        if (era === 's') return 1925 + parseInt(year);
        if (era === 'h') return 1988 + parseInt(year);
        if (era === 'r') return 2018 + parseInt(year);
    }

    function japanCalendarToADDate(era, year, month, day) {
        const ad = japanCalendarToAD(era, year);
        const zeroPaddingMonth = ('0' + month).slice(-2)
        const zeroPaddingDay = ('0' + day).slice(-2)
        if (ad) {
            return `${ad}-${zeroPaddingMonth}-${zeroPaddingDay}`;
        } else {
            return '';
        }
    }

    const toJpEraText = (jpEraNo) => {
        switch (jpEraNo) {
            case 's':
                return '昭和';
            case 'h':
                return '平成';
            case 'r':
                return '令和';
            default:
                return '';
        }
    }

    return { japanCalendarToAD, japanCalendarToADDate, toJpEraText }
})
