<template>
    <div class="d-flex justify-content-center">
        <nav aria-label="Page navigation">
            <ul v-if="data.pages.length > 0" class="pagination">
                <li v-for="pageNum in data.pages"
                    :class="addClassName(pageNum, data.currentPage)"
                    class="page-item">
                    <a :class="addClassName(pageNum, data.currentPage)"
                       class="page-link"
                       @click.prevent="fncClick"
                    >
                        {{ pageNum }}
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup>
/** Vue3 */
import {reactive, watch, defineProps, defineEmits} from 'vue';
/* \\\\END Vue3 */

/** props */
const props = defineProps({
    addClass: {
        type: String,
        default: '',
    },
    customEvent: {
        type: String,
        required: true,
    },
    midSize: {
        type: Number,
        required: true,
    },
    currentPage: {
        type: Number,
        required: true,
    },
    perPage: {
        type: Number,
        required: true,
    },
    total: {
        type: Number,
        required: true,
    },
});

/** Data */
const data = reactive({
    pages: [],
    midSize: props.midSize,
    perPage: props.perPage,
    total: props.total,
    size: props.midSize * 2 + 1,
    currentPage: props.currentPage,
    totalPages: Math.ceil(props.total / props.perPage),
});

const emit = defineEmits();

const generatePagination = () => {
    if (data.size < data.totalPages) {
    } else {
    }
    let pageNum = 1;
    if (0 < data.currentPage - data.midSize && data.currentPage + data.midSize < data.totalPages) {
        pageNum = data.currentPage - data.midSize;
    } else if (0 < data.currentPage - data.midSize) {
        pageNum = data.totalPages - (data.midSize * 2);
    }
    for (let idx = 0; idx < data.size; idx++) {
        if (pageNum > data.totalPages) break;
        if (pageNum > 0) {
            data.pages.push(pageNum);
        }
        ++pageNum;
    }
};
generatePagination();
watch(props, () => {
    data.midSize = props.midSize;
    data.perPage = props.perPage;
    data.total = props.total;
    data.size = props.midSize * 2 + 1;
    data.currentPage = props.currentPage;
    data.totalPages = Math.ceil(props.total / props.perPage);
    data.pages = [];
    generatePagination();
});

const addClassName = (pageNum, currentPage) => {

    if (pageNum === currentPage) {
        // return 'current';
        return 'active current';
    } else {
        return '';
    }
};

const fncClick = (e) => {
    emit(props.customEvent, e);
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/modules/variables";
//
//ul {
//    display: flex;
//}
//
//li {
//
//}
//
//a {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    width: 34px;
//    height: 38px;
//    border-radius: 3px;
//    &:hover {
//        background-color: $base;
//    }
//    &.current {
//        background-color: $accent1;
//    }
//}

</style>
