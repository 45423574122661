<script setup>
import {ref} from "vue";
import {useLoaderStore} from "../stores/loader";
import {useJapanCalendarStore} from "../stores/japanCalendar";
import axios from "axios";

const loader = useLoaderStore();
const japanCalendar = useJapanCalendarStore();

const firstAttack = async () => {
    // ローディング開始
    loader.start();
    const response = await axios.get('/api/client/syaken-40days-ago');
    console.log(response.data);
    resultSyaken.value = response.data;
    // ローディング終了
    loader.finish();
}
firstAttack();

const resultSyaken = ref('');

</script>

<template>
    <main>
        <div class="container">
            <h1 class="fz_20 fz_md_24">HOME</h1>
            <div class="table-wrap-outer">
                <h2>車検45日前</h2>
                <div class="table-wrap">
                    <table>
                        <tr>
                            <th>氏名</th>
                            <th>かな</th>
                            <th>車名</th>
                            <th>ナンバー</th>
                            <th>車検満了日</th>
                        </tr>
                        <template v-if="resultSyaken['count_syaken'] > 0">
                            <tr v-for="data in resultSyaken['data_syaken']">
                                <td class="td1">
                                    <router-link :to="{name: 'clientData', params: {cid: data.id}}">
                                        {{ data.family_name }} {{ data.given_name }}
                                    </router-link>
                                </td>
                                <td class="td2">{{ data.family_name_kana }} {{ data.given_name_kana }}</td>
                                <td class="td3">{{ data.car_name }}</td>
                                <td class="td4">{{ data.no_chiki }} {{ data.no_bunrui }} {{ data.no_hanbetsu }}
                                    {{ data.no_shitei }}
                                </td>
                                <td>{{japanCalendar.toJpEraText(data.syaken_jp_era)}}{{data.syaken_jp_year}}年{{data.syaken_month}}月{{data.syaken_day}}日</td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="4">該当するデータはありません。</td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
            <div class="table-wrap-outer mt-40">
                <h2>１年点検40日前</h2>
                <div class="table-wrap">
                    <table>
                        <tr>
                            <th>氏名</th>
                            <th>かな</th>
                            <th>車名</th>
                            <th>ナンバー</th>
                            <th>車検満了日</th>
                        </tr>
                        <template v-if="resultSyaken['count_ichinen'] > 0">
                            <tr v-for="data in resultSyaken['data_ichinen']">
                                <td class="td1">
                                    <router-link :to="{name: 'clientData', params: {cid: data.id}}">
                                        {{ data.family_name }} {{ data.given_name }}
                                    </router-link>
                                </td>
                                <td class="td2">{{ data.family_name_kana }} {{ data.given_name_kana }}</td>
                                <td class="td3">{{ data.car_name }}</td>
                                <td class="td4">{{ data.no_chiki }} {{ data.no_bunrui }} {{ data.no_hanbetsu }}
                                    {{ data.no_shitei }}
                                </td>
                                <td>{{japanCalendar.toJpEraText(data.syaken_jp_era)}}{{data.syaken_jp_year}}年{{data.syaken_month}}月{{data.syaken_day}}日</td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="4">該当するデータはありません。</td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
            <div class="table-wrap-outer mt-40">
                <h2>半年点検40日前</h2>
                <div class="table-wrap">
                    <table>
                        <tr>
                            <th>氏名</th>
                            <th>かな</th>
                            <th>車名</th>
                            <th>ナンバー</th>
                            <th>車検満了日</th>
                        </tr>
                        <template v-if="resultSyaken['count_hantoshi'] > 0">
                            <tr v-for="data in resultSyaken['data_hantoshi']">
                                <td class="td1">
                                    <router-link :to="{name: 'clientData', params: {cid: data.id}}">
                                        {{ data.family_name }} {{ data.given_name }}
                                    </router-link>
                                </td>
                                <td class="td2">{{ data.family_name_kana }} {{ data.given_name_kana }}</td>
                                <td class="td3">{{ data.car_name }}</td>
                                <td class="td4">{{ data.no_chiki }} {{ data.no_bunrui }} {{ data.no_hanbetsu }}
                                    {{ data.no_shitei }}
                                </td>
                                <td>{{japanCalendar.toJpEraText(data.syaken_jp_era)}}{{data.syaken_jp_year}}年{{data.syaken_month}}月{{data.syaken_day}}日</td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="4">該当するデータはありません。</td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
            <div class="table-wrap-outer mt-40">
                <h2>新車１ヶ月点検10日前</h2>
                <div class="table-wrap">
                    <table>
                        <tr>
                            <th>氏名</th>
                            <th>かな</th>
                            <th>車名</th>
                            <th>ナンバー</th>
                            <th>車検満了日</th>
                        </tr>
                        <template v-if="resultSyaken['count_shinsya'] > 0">
                            <tr v-for="data in resultSyaken['data_shinsya']">
                                <td class="td1">
                                    <router-link :to="{name: 'clientData', params: {cid: data.id}}">
                                        {{ data.family_name }} {{ data.given_name }}
                                    </router-link>
                                </td>
                                <td class="td2">{{ data.family_name_kana }} {{ data.given_name_kana }}</td>
                                <td class="td3">{{ data.car_name }}</td>
                                <td class="td4">{{ data.no_chiki }} {{ data.no_bunrui }} {{ data.no_hanbetsu }}
                                    {{ data.no_shitei }}
                                </td>
                                <td>{{japanCalendar.toJpEraText(data.syaken_jp_era)}}{{data.syaken_jp_year}}年{{data.syaken_month}}月{{data.syaken_day}}日</td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="4">該当するデータはありません。</td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
            <div class="table-wrap-outer mt-40">
                <h2>保険満期２ヶ月前</h2>
                <div class="table-wrap">
                    <table>
                        <tr>
                            <th>氏名</th>
                            <th>かな</th>
                            <th>車名</th>
                            <th>ナンバー</th>
                            <th>車検満了日</th>
                        </tr>
                        <template v-if="resultSyaken['count_hoken'] > 0">
                            <tr v-for="data in resultSyaken['data_hoken']">
                                <td class="td1">
                                    <router-link :to="{name: 'clientData', params: {cid: data.id}}">
                                        {{ data.family_name }} {{ data.given_name }}
                                    </router-link>
                                </td>
                                <td class="td2">{{ data.family_name_kana }} {{ data.given_name_kana }}</td>
                                <td class="td3">{{ data.car_name }}</td>
                                <td class="td4">{{ data.no_chiki }} {{ data.no_bunrui }} {{ data.no_hanbetsu }}
                                    {{ data.no_shitei }}
                                </td>
                                <td>{{japanCalendar.toJpEraText(data.syaken_jp_era)}}{{data.syaken_jp_year}}年{{data.syaken_month}}月{{data.syaken_day}}日</td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="4">該当するデータはありません。</td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
        </div>
    </main>
</template>

<style scoped lang="scss">
@import "src/assets/scss/modules/variables";

.table-wrap-outer {
    overflow: hidden;
    border-radius: 5px;
    background-color: #FFF;

    h2 {
        margin-bottom: 0;
        padding: 40px 20px 20px;
        font-size: 1.7rem;
        font-weight: 700;
        @media ($min-md) {
            font-size: 2rem;
        }
    }

}

.table-wrap {
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;

    table {
        table-layout: fixed;
        width: 100%;
        min-width: 650px;
        white-space: nowrap;
        @media ($min-md) {
            min-width: 800px;
        }
    }

    tr {
        &:first-child {
            //display: none;
            border-top: solid 1px $gray-300;
            border-bottom: solid 1px $gray-300;
            @media ($min-md) {
                //display: table-row;
            }
        }
    }

    th {
        padding: 10px 15px;
    }

    td {
        padding: 10px 15px;
        //display: block;
        //width: 100%;
        //-webkit-line-clamp: 1;
        //display: -webkit-box;
        //-webkit-box-orient: vertical;
        //overflow: hidden;
        @media($min-md) {
            //display: table-cell;
            width: auto;
        }

        a {
            display: block;
            text-decoration: none;
            color: $accent4;

            :hover {
                color: $primary;
            }
        }
    }
}
</style>
