<script setup>
import {ref, reactive} from "vue";
import axios from "axios";
import Pagination from "../components/items/Pagination.vue";
import {useLoaderStore} from "../stores/loader";
import {useJapanCalendarStore} from "../stores/japanCalendar";

const loader = useLoaderStore();
const japanCalendar = useJapanCalendarStore();

const file = ref('');
const form = ref(null);
// 検索後に取得したデータ。
const result = ref('');

const familyName = ref('');
const familyNameMatch = ref(false);
const givenName = ref('');
const givenNameMatch = ref(false);
const familyNameKana = ref('');
const familyNameKanaMatch = ref(false);
const givenNameKana = ref('');
const givenNameKanaMatch = ref(false);
const tel = ref('');
const postCode = ref('');
const email = ref('');
const noShitei = ref('');

const pagination = reactive({
    page: Number((new URLSearchParams(window.location.search)).get('page')) ? Number((new URLSearchParams(window.location.search)).get('page')) : 1,//ページ番号
    perPage: 10, //1回のリクエストで返すレコードの数を指定します。
    total: 0, //ヒットした記事の総数
})


/**
 *
 */
const firstAttack = async () => {
    // ローディング開始
    loader.start();
    if (location.search) {
        const response = await axios.get('/api/client/search' + location.search);
        console.log(response.data);
        result.value = response.data;
        pagination.total = response.data.total;
        // ローディング終了
        loader.finish();
    }
    // ローディング終了
    loader.finish();
}
firstAttack();

/**
 *
 * @param isClickSearchBtn 検索ボタンを押した時は、true。ページネーションをクリックした時は、false
 * @returns {Promise<void>}
 */
const fncSubmit = async (isClickSearchBtn) => {
    // ローディング開始
    loader.start();

    // URLSearchParamsは、https://qiita.com/taroc/items/f22f7dd5d6d5443c72a4 参照
    const urlSearchParams = new URLSearchParams(window.location.search)

    // 現在のページNo.
    urlSearchParams.set('page', pagination.page);

    // 1回のリクエストで返すレコードの数の設定（per_page）
    urlSearchParams.set('per_page', pagination.perPage);

    /** 姓 **/
    //　まずはURLのリセット。条件に応じてパラメータを追加していく。
    urlSearchParams.delete('family_name');
    urlSearchParams.delete('family_name_match');
    // 苗字の入力があった時。
    if (familyName.value) {
        urlSearchParams.set('family_name', familyName.value);
    }
    // 苗字の入力があった時と、前方一致がtrueの時。
    if (familyName.value && familyNameMatch.value) {
        urlSearchParams.set('family_name_match', familyNameMatch.value);
    }

    /** 名 **/
    //　まずはURLのリセット。条件に応じてパラメータを追加していく。
    urlSearchParams.delete('given_name');
    urlSearchParams.delete('given_name_match');
    // 名前の入力があった時。
    if (givenName.value) {
        urlSearchParams.set('given_name', givenName.value);
    }
    // 名前の入力があった時と、前方一致がtrueの時。
    if (givenName.value && givenNameMatch.value) {
        urlSearchParams.set('given_name_match', givenNameMatch.value);
    }

    /** 姓（かな） **/
    //　まずはURLのリセット。条件に応じてパラメータを追加していく。
    urlSearchParams.delete('family_name_kana');
    urlSearchParams.delete('family_name_kana_match');
    // 苗字（かな）の入力があった時。
    if (familyNameKana.value) {
        urlSearchParams.set('family_name_kana', familyNameKana.value);
    }
    // 苗字（かな）の入力があった時と、前方一致がtrueの時。
    if (familyNameKana.value && familyNameKanaMatch) {
        urlSearchParams.set('family_name_kana_match', familyNameKanaMatch.value);
    }

    /** 名（かな） **/
    //　まずはURLのリセット。条件に応じてパラメータを追加していく。
    urlSearchParams.delete('given_name_kana');
    urlSearchParams.delete('given_name_kana_match');
    // 名前（かな）の入力があった時。
    if (givenNameKana.value) {
        urlSearchParams.set('given_name_kana', givenNameKana.value);
    }
    // 名前（かな）の入力があった時と、前方一致がtrueの時。
    if (givenNameKana.value && givenNameKanaMatch) {
        urlSearchParams.set('given_name_kana_match', givenNameKanaMatch.value);
    }

    /** TEL **/
    //　まずはURLのリセット。条件に応じてパラメータを追加していく。
    urlSearchParams.delete('tel');
    // TELの入力があった時。
    if (tel.value) {
        urlSearchParams.set('tel', tel.value);
    }

    /** 郵便番号 **/
    //　まずはURLのリセット。条件に応じてパラメータを追加していく。
    urlSearchParams.delete('post_code');
    // TELの入力があった時。
    if (postCode.value) {
        urlSearchParams.set('post_code', postCode.value);
    }

    /** Eメール **/
    //　まずはURLのリセット。条件に応じてパラメータを追加していく。
    urlSearchParams.delete('email');
    // TELの入力があった時。
    if (email.value) {
        urlSearchParams.set('email', email.value);
    }

    /** ナンバー **/
    //　まずはURLのリセット。条件に応じてパラメータを追加していく。
    urlSearchParams.delete('no_shitei');
    // TELの入力があった時。
    if (noShitei.value) {
        urlSearchParams.set('no_shitei', noShitei.value);
    }

    // urlを生成して、表示させる。
    // history.replaceState('', '', `?${urlSearchParams.toString()}`);
    history.pushState('', '', `?${urlSearchParams.toString()}`);
    history.back();
    history.forward();

    const response = await axios.get('/api/client/search?' + urlSearchParams.toString());

    console.log(urlSearchParams.toString());
    console.log(response.data);
    result.value = response.data;
    pagination.total = response.data.total;

    // ローディング終了
    loader.finish();
}

const fncPaginationBtnClick = (e) => {
    let el = e.currentTarget;
    pagination.page = parseInt(el.textContent);
    fncSubmit(false);
}


</script>

<template>
    <main>
        <keep-alive>
            <div class="container">
                <form ref="form"
                      @submit.prevent="fncSubmit(true)"
                >
                    <!-- 姓 -->
                    <div class="d-flex">
                        <div class="w-50">
                            <label for="family-name" class="form-label">姓</label>
                            <input id="family-name"
                                   class="form-control"
                                   type="text"
                                   name="family_name"
                                   v-model="familyName"
                            >
                            <div class="form-check form-switch">
                                <input id="family-name-match"
                                       class="form-check-input"
                                       type="checkbox"
                                       name="family_name_match"
                                       v-model="familyNameMatch"
                                >
                                <label for="family-name-match" class="form-label">
                                    前方一致
                                </label>
                            </div>
                        </div>
                        <div class="w-50">
                            <!-- 名 -->
                            <label for="given-name" class="form-label">名</label>
                            <input id="given-name"
                                   class="form-control"
                                   type="text"
                                   name="given_name"
                                   v-model="givenName"
                            >
                            <div class="form-check form-switch">
                                <input id="given-name-match"
                                       class="form-check-input"
                                       type="checkbox"
                                       name="given_name_match"
                                       v-model="givenNameMatch"
                                >
                                <label for="given-name-match" class="form-label">
                                    前方一致
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex mt-10">
                        <div class="w-50">
                            <!--姓（かな）-->
                            <label for="family-name-kana" class="form-label">姓（かな）</label>
                            <input id="family-name-kana"
                                   class="form-control"
                                   type="text"
                                   v-model="familyNameKana"
                            >
                            <div class="form-check form-switch">
                                <input id="family-name-kana-match"
                                       class="form-check-input"
                                       type="checkbox"
                                       name="family_name_kana_match"
                                       v-model="familyNameKanaMatch"
                                >
                                <label for="family-name-kana-match" class="form-label">
                                    前方一致
                                </label>
                            </div>
                        </div>
                        <div class="w-50">
                            <!--名（かな）-->
                            <label for="given-name-kana" class="form-label">名（かな）</label>
                            <input id="given-name-kana"
                                   class="form-control"
                                   type="text"
                                   name="given_name_kana"
                                   v-model="givenNameKana"
                            >
                            <div class="form-check form-switch">
                                <input id="given-name-kana-match"
                                       class="form-check-input"
                                       type="checkbox"
                                       name="given_name_kana_match"
                                       v-model="givenNameKanaMatch"
                                >
                                <label for="given-name-kana-match" class="form-label">
                                    前方一致
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- TEL -->
                    <label for="tel" class="form-label mt-10">TEL</label>
                    <input id="tel"
                           class="form-control"
                           type="text"
                           name="tel"
                           v-model="tel"
                    >
                    <!-- 郵便番号 -->
                    <label for="post-code" class="form-label mt-10">郵便番号</label>
                    <input id="post-code"
                           class="form-control"
                           type="text"
                           name="post_code"
                           v-model="postCode"
                    >
                    <!-- Eメール -->
                    <label for="email" class="form-label mt-10">Eメールアドレス</label>
                    <input id="email"
                           class="form-control"
                           type="text"
                           name="email"
                           v-model="email"
                    >
                    <!-- ナンバー -->
                    <label for="no-shitei" class="form-label mt-10">ナンバー</label>
                    <input id="no-shitei"
                           class="form-control"
                           type="text"
                           name="no-shitei"
                           v-model="noShitei"
                    >
                    <input class="btn btn-sm btn-primary mt-20" type="submit" value="検索">
                </form>
                <div class="mt-50">
                    <div class="table-wrap-outer">
                        <div class="table-wrap">
                            <table>
                                <tr>
                                    <th>氏名</th>
                                    <th>氏名（かな）</th>
                                    <th>車名</th>
                                    <th>ナンバー</th>
                                    <th>車検満了日</th>
                                </tr>
                                <template v-if="result['count'] > 0">
                                    <tr v-for="data in result['data']">
                                        <td>
                                            <router-link :to="{name: 'clientData', params: {cid: data.client_id}}">
                                                {{ data.family_name }} {{ data.given_name }}
                                            </router-link>
                                        </td>
                                        <td>{{ data.family_name_kana }} {{ data.given_name_kana }}</td>
                                        <td>{{ data.car_name }}</td>
                                        <td>{{ data.no_chiki }} {{ data.no_bunrui }} {{ data.no_hanbetsu }} {{
                                                data.no_shitei
                                            }}
                                        </td>
                                        <td>{{ japanCalendar.toJpEraText(data.syaken_jp_era) }}{{
                                                data.syaken_jp_year
                                            }}{{ data.syaken_day ? '年' : '' }}{{
                                                data.syaken_month
                                            }}{{ data.syaken_day ? '月' : '' }}{{ data.syaken_day }}{{
                                            data.syaken_day ? '日' : ''
                                          }}
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="5">該当するデータがありません。</td>
                                    </tr>
                                </template>
                            </table>
                            <div class="mt-30 mb-30">
                                <pagination :total="pagination.total"
                                            :per-page="pagination.perPage"
                                            :current-page="pagination.page"
                                            :mid-size="2"
                                            custom-event="fncEmit"
                                            @fncEmit="fncPaginationBtnClick"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </keep-alive>
    </main>
</template>

<style scoped lang="scss">
@import "src/assets/scss/modules/variables";

input {
    font-size: 1.6rem;
}

.table-wrap-outer {
    overflow: hidden;
    border-radius: 8px;
    background-color: #FFF;

    h2 {
        margin-bottom: 0;
        padding: 40px 20px 20px;
        font-size: 1.7rem;
        font-weight: 700;
        @media ($min-md) {
            font-size: 2rem;
        }
    }

}

.table-wrap {
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;

    table {
        //table-layout: fixed;
        width: 100%;
        min-width: 650px;
        white-space: nowrap;
        @media ($min-md) {
            min-width: 800px;
        }
    }

    tr {
        &:first-child {
            //display: none;
            border-top: solid 1px $gray-300;
            border-bottom: solid 1px $gray-300;
            @media ($min-md) {
                //display: table-row;
            }
        }
    }

    th {
        padding: 10px 15px;
    }

    td {
        padding: 10px 15px;
        //display: block;
        //width: 100%;
        //-webkit-line-clamp: 1;
        //display: -webkit-box;
        //-webkit-box-orient: vertical;
        //overflow: hidden;
        @media($min-md) {
            //display: table-cell;
            width: auto;
        }

        a {
            display: block;
            text-decoration: none;
            color: $accent4;

            :hover {
                color: $primary;
            }
        }
    }
}
</style>
