<script setup>
import {useRouter} from "vue-router";
import axios from "axios";
import Hamburger from "../components/items/Hamburger.vue";
import ToggleMenu from "../components/items/ToggleMenu.vue";

const router = useRouter();

const logout = async () => {
    const response = await axios.post('/api/admin/logout');
    if (response.data.result === 'success') {
        await router.push({name: 'loginAdminPrimary'});
    }
}
</script>

<template>
    <header class="header-side d-none d-md-block">
        <ul>
            <li>
                <router-link :to="{name: 'home'}"
                             active-class="active"
                             exact
                >HOME
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'clientSearch'}"
                             active-class="active"
                             exact
                >検索
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'clientRegister', params: {type: 'register'}}"
                             active-class="active"
                             exact
                >新規登録
                </router-link>
            </li>
            <li>
                <a href="" @click.prevent="logout()">ログアウト</a>
            </li>
        </ul>
    </header>
    <Hamburger/>
    <ToggleMenu/>
</template>

<style scoped lang="scss">
@import "src/assets/scss/modules/variables";

.header-side {
    position: fixed;
    width: 200px;
    height: 100vh;
    background-color: #fff;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    font-size: 1.8rem;
    font-weight: 700;
    text-decoration: none;
}

.active {
    background-color: $primary;
    color: $base;
}

</style>
